export default function getLinkFieldUrl(link: any): string {
  let url = link.cached_url.replace(/\/$/, "").replace("index", "");

  if (link.linktype === "email") return `mailto:${url}`;

  if (link.linktype === "url") {
    if (!url.startsWith("/") && !url.startsWith("http") && !url.startsWith("mailto") && !url.startsWith("tel")) url = `/${url}`;
  }

  return link.linktype === "story" ? `/${url}${link.anchor ? `#${link.anchor}` : ""}` : url;
}
